// 
// type.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

h1, .h1 {
  margin-bottom: $headings-margin-bottom;
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }
}

h2, .h2 {
  margin-bottom: $headings-margin-bottom;
}

h3, .h3 {
  margin-bottom: ($headings-margin-bottom * .75);
}

h4, .h4 {
  margin-bottom: ($headings-margin-bottom * .5);
}

h5, .h5 {
  margin-bottom: ($headings-margin-bottom * .5);
}

h6, .h6 {
  margin-bottom: ($headings-margin-bottom * .5);
}

.display-5 {
  font-size: $display5-size;
  font-weight: $display5-weight;
  letter-spacing: -0.02em;
}


// Links

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {

  > a {
    color: inherit;
  }
}

// Type display classes

.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: $display-letter-spacing;
}

// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  letter-spacing: $headings-letter-spacing;

  color: $gray-600;
}

h6.text-uppercase {
  letter-spacing: .08em;
}

// Bold, strong

b, strong {
  font-weight: $font-weight-bold;
}


// Links, buttons
//
// Removes focus outline

a, button {

  &:focus {
    outline: none !important;
  }
}


// 
// Template =====================================
//

// Include Cerebri Sans

@font-face {
  font-family: 'Cerebri Sans';
  src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.eot');
  src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.eot?#iefix') format('embedded-opentype'), url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.woff') format('woff'), url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.eot');
  src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.eot?#iefix') format('embedded-opentype'), url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.woff') format('woff'), url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cerebri Sans';
  src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.eot');
  src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.eot?#iefix') format('embedded-opentype'), url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.woff') format('woff'), url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

// Include Montserrat

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

// Include Poppins

// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

@font-face {
  font-family: 'Poppins';
  src: url('#{$path-to-fonts}/poppins/Poppins-Regular.eot');
  src: url('#{$path-to-fonts}/poppins/Poppins-Regular.eot?#iefix') format('embedded-opentype'), url('#{$path-to-fonts}/poppins/Poppins-Regular.woff') format('woff'), url('#{$path-to-fonts}/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$path-to-fonts}/poppins/Poppins-Medium.eot');
  src: url('#{$path-to-fonts}/poppins/Poppins-Medium.eot?#iefix') format('embedded-opentype'), url('#{$path-to-fonts}/poppins/Poppins-Medium.woff') format('woff'), url('#{$path-to-fonts}/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('#{$path-to-fonts}/poppins/Poppins-SemiBold.eot');
  src: url('#{$path-to-fonts}/poppins/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'), url('#{$path-to-fonts}/poppins/Poppins-SemiBold.woff') format('woff'), url('#{$path-to-fonts}/poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}


// Subrayado titulares

// h1.underline {

//   &:before {
//     content: '';
//     width: 25%;
//     position: absolute;
//     top: 3rem;
//     left: 0.5rem;
//     border: 0.5rem solid $primary;
//   }
// }

.display-divider {
  border-bottom: 1rem solid $primary;
  width: 8rem;
  display: block;
  line-height: 0.5rem;
  margin-bottom: 1.5rem;

  &-primary,
  &-blue {
    border-color: $primary;
  }

  &-indigo {
    border-color: $indigo;
  }
  
  &-purple {
    border-color: $purple;
  }
  
  &-pink {
    border-color: $pink;
  }
  
  &-red {
    border-color: $red;
  }
  
  &-orange {
    border-color: $orange;
  }
  
  &-yellow {
    border-color: $yellow;
  }
  
  &-green {
    border-color: $green;
  }
  
  &-teal {
    border-color: $teal;
  }
  
  &-cyan {
    border-color: $cyan;
  }
}