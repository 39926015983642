html {
  height: 100%;
  margin: auto;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-close {
  background-color: transparent;
  border: none;
}

#form-rrss .invalid-feedback {
  display: block;
}

.how-works > div {
  max-width: 428px;
}

.home {
  font-size: 20px;
  color: #727272;
}

.text-blue-sigmados {
  color: #5fa6d9;
}

.background-blue-sigmados {
  background-color: #5fa6d9;
}
