// 
// list-group.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

// Contextual variants
//
// Changing the Bootstrap color modifier classes to be full opacity background with yiq calculated font color

@each $color, $value in $theme-colors {
  @include list-group-item-variant($color, $value, color-yiq($value));
}


// List group sizing

.list-group-lg .list-group-item {
  padding-top: $list-group-item-padding-y-lg;
  padding-bottom: $list-group-item-padding-y-lg;
}

// List group flush

.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0;
}

// List group focus

.list-group-focus .list-group-item:focus .text-focus {
  color: $link-color !important;
}


//
// Template ===================================
//


// Activity

.list-group-activity .list-group-item {
  border: 0;
}

.list-group-activity .list-group-item:not(:last-child)::before {
  content: '';
  position: absolute;
  top: $list-group-item-padding-y;
  left: $avatar-size-sm / 2;
  height: 100%;
  border-left: $border-width solid $border-color;
}


// List Points Colors
//
// A simple list with color points

ul.list-points {
  list-style: none;
  padding-left: 0;

  li {
    font-size: 0.875rem;
    line-height: 2rem;
    position: relative;
    padding-left: 1.5rem;
  }

  li::before {
    content: "\2022";
    color: $primary;
    font-size: 3rem;
    font-weight: normal;
    vertical-align: middle;
    position: absolute;
    left: 0;
  }

  // blue = primary
  &.list-points-blue {

    li::before {
      color: $blue;
    }
  }
  
  // green
  &.list-points-green {

    li::before {
      color: $green;
    }
  }

  // orange
  &.list-points-orange {

    li::before {
      color: $orange;
    }
  }

  // gray
  &.list-points-gray {

    li::before {
      color: $gray-600;
    }
  }
  
  // gray
  &.list-points-yellow {

    li::before {
      color: $yellow;
    }
  }

  // first strong
  &.list-points-first-strong {

    li {
      
      &:first-child {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }
}

ul.list-description {
  list-style: none;
  padding-left: 0;
  margin-top: 0.5rem;

  li {
    font-size: 0.875rem;
    line-height: 1.5rem;
    position: relative;

    &:first-child {
      font-weight: 600;
      text-transform: uppercase;
    }
  }
}