.social-icons {
  position: relative;
  width: 4rem;
  height: 4rem;

  span {
    position: absolute;
    top: -2rem;
    right: -2rem;

    .badge-icon {
      position: relative;
      
      .icon {
        position: absolute;
        top: -0.25rem;
        right: 0.75rem;
        width: 2.75rem;
        height: auto;
      }

      .text {
        position: absolute;
        top: 0.55rem;
        right: 1.6rem;
        font-size: $font-size-sm;
        font-weight: 700;
        letter-spacing: -0.05rem;
        color: $orange;
        text-align: center;
      }

    }
  }
  
  .icon-base {
    width: 3rem;
    height: auto;
  }
}