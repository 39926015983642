//
// code.scss
// Extended from Bootstrap
//

// 
// Template =================================
//

// Highlight
//
// Hightlight.js overrides

.highlight {
  padding: 0;
}