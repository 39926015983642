footer {
  padding-top: 1rem;
  padding-bottom: 0.5rem;

  .text-legal {
    font-size: $font-size-xs;
    font-weight: 400;
    color: $gray-400;
  }

  .nav-footer {

    .nav-item {
      border-right: 1px solid $gray-200;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      &:last-child {
        border-right: 0;
      }
    }

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      font-size: $font-size-xs;
      font-weight: 400;
      color: $gray-200;
    }
  }
}

// Alineación

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

.main-content {
  padding-bottom: 2rem;
}