#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main-content {
  overflow-x: hidden;
}

.btn-primary {
  color: $white;
}


.form-group {
  .react-datepicker-component {
    .react-datepicker-input {
      position: unset;
      min-width: unset;
      height: unset;
      background: unset;
      border: unset;
      border-radius: unset;

      &.is-open,
      &:hover {
        background: unset;
        border: unset;
      }

      input {
        display: block;
        width: 100%;
        height: calc(1.5em + 1rem + 2px);
        padding: 0.5rem 0.75rem;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.5;
        color: #666666;
        background-color: #ffffff;
        background-clip: padding-box;
        border: 1px solid #5fa6d9;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:read-only {
          cursor: pointer;
        }
      }
    }
  }
}

$cursors: (alias, all-scroll, auto, cell, context-menu, col-resize, copy,
crosshair, default, e-resize, ew-resize, grab, grabbing, help, move,
n-resize, ne-resize, nesw-resize, ns-resize, nw-resize, nwse-resize,
no-drop, none, not-allowed, pointer, progress, row-resize, s-resize,
se-resize, sw-resize, text, URL, vertical-text, w-resize, wait, zoom-in,
zoom-out, initial, inherit);

@each $var in $cursors {
    .cursor-#{$var} {
        cursor: $var !important;
    }
}

.sticky-bottom {
  top:0;
}


.preloader-full-page {
  min-height: calc(100vh - 99px - 5.675rem - 2rem);
}

// Section positions images
//
// --> Posicionamos las imágenes de cada sección de la página de nosotros

// 1 ¿Quienes somos?

.section-we .images {
  position: relative;
  min-height: 21rem;
  //   border: 2px dashed gold;
  margin: -4rem 0 6rem 0;

  .image-01,
  .image-02,
  .image-03 {
    position: absolute;
  }

  .image-01 {
    max-width: 21rem;
    top: -1rem;
    right: -6rem;
  }

  .image-02 {
    max-width: 24rem;
    top: -0.5rem;
    right: 1rem;
  }

  .image-03 {
    max-width: 16rem;
    bottom: -2rem;
    right: 1.5rem;
  }

  @include media-breakpoint-up(lg) {
    // border-color: lightblue;
    margin: 0;

    .image-01 {
      max-width: 21rem;
      top: -1rem;
      right: 5rem;
    }

    .image-02 {
      max-width: 21rem;
      top: -1rem;
      right: 7rem;
    }

    .image-03 {
      max-width: 21rem;
      bottom: -2rem;
      right: 6.5rem;
    }
  }
}

// 2 ¿Qué es Trustsurvey?

.section-what {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.section-what .images {
  position: relative;
  min-height: 31rem;
  //   border: 2px dashed gold;

  .image-01,
  .image-02,
  .image-03 {
    position: absolute;
  }

  .image-01 {
    max-width: 6rem;
    top: -4rem;
    right: -4rem;
  }

  .image-02 {
    max-width: 24rem;
    top: -2rem;
    left: -1.25rem;
  }

  .image-03 {
    max-width: 10rem;
    bottom: 2rem;
    left: -6rem;
  }

  @include media-breakpoint-up(lg) {
    // border-color: lightblue;
    min-height: 21rem;

    .image-01 {
      max-width: 4rem;
      top: -2rem;
      left: 23rem;
    }

    .image-02 {
      max-width: 21rem;
      top: -1rem;
      left: 5rem;
    }

    .image-03 {
      max-width: 8rem;
      bottom: -6rem;
      left: 3.75rem;
    }
  }
}

// 3. HOME -> El primer panel social

.section-panel {
  .btn {
    width: 100%;
    font-size: $font-size-lg;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;

    @include media-breakpoint-up(lg) {
      width: auto;
      padding-top: auto;
      padding-bottom: auto;
      font-size: $font-size-base;
    }
  }
}

.section-panel .images {
  // border: 1px solid gold;
  border: 1px solid transparent;

  .image-02 {
    max-width: 100%;
  }
  
}

// Home, first section
.image-01 {
  position: absolute;
  // top: -20%;
  bottom: 80%;
  right: 0;
  max-width: 60%;
  // border: 1px solid darkorange;

  @include media-breakpoint-up(lg) {
    bottom: 40%;
    top: auto;
    // right: 5%;
    // right: 0.5%;
    right: -5%;
    left: auto;
    // max-width: 30%;
    max-width: 40%;
  }
}

// Home, second section

.image-03 {
  position: absolute;
  top: 25%;
  left: 0;
  max-width: 85%;

  @include media-breakpoint-up(lg) {
    // top: 44%;
    top: 46%;
    // right: 55%;
    left: -10%;
    max-width: 40%;
  }
}

.image-04 {
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 80%;
  }
}

// Home, four section

.image-05 {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 90%;

  @include media-breakpoint-up(lg) {
    top: 20%;
    right: 10%;
    max-width: 35%;
  }
}

.image-06 {
  max-width: 80%;
}

// Nosotros, first section

.image-07 {
  position: absolute;
  top: -30%;
  right: 0;
  max-width: 80%;

  @include media-breakpoint-up(lg) {
    // top: -10%;
    // right: 5%;
    top: -15%;
    right: 0;
    max-width: 30%;
    // max-width: 18.75rem; // 300px
  }
}

.image-08 {
  max-width: 70%;
  // max-width: 18.75rem; // 300px
  // margin-left: 5rem;
  margin-left: 4rem;
  margin-top: -4rem;

  @include media-breakpoint-up(lg) {
    margin-left: 10rem;
    margin-top: 0rem;
  }
}

.image-09 {
  max-width: 50%;
  // max-width: 16rem; // 256px
  margin-left: 6rem;
  margin-top: 5rem;
  margin-bottom: 2rem;

  @include media-breakpoint-up(lg) {
    margin-left: 11rem;
    margin-top: 4rem;
    margin-bottom: 0;
  }
}

// Nosotros, second section

.image-10 {
  position: absolute;
  top: -2%;
  right: 0;
  max-width: 15%;

  @include media-breakpoint-up(lg) {
    top: -2%;
    left: 38%;
    max-width: 5%;
  }
}

.image-11 {
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-width: 80%;
    margin-left: 1.5rem;
  }
}

.image-12 {
  max-width: 20%;
  position: absolute;
  bottom: -2%;
  left: -2%;
}

// Catálogo, first section

.image-13 {
  position: absolute;
  top: -20%;
  right: 0;
  max-width: 80%;

  @include media-breakpoint-up(lg) {
    top: -10%;
    right: 8%;
    max-width: 30%;
  }
}

.image-14 {
  max-width: 75%;
  margin-left: 2rem;

  @include media-breakpoint-up(lg) {
    max-width: 50%;
    margin-left: 10rem;
    margin-top: 0;
  }
}

// Catálogo, first section

.image-15 {
  position: absolute;
  top: -10%;
  left: 0;
  max-width: 70%;

  @include media-breakpoint-up(lg) {
    top: -20%;
    left: 8%;
    max-width: 30%;
  }
}

.image-16 {
  max-width: 75%;
  margin-left: 2rem;

  @include media-breakpoint-up(lg) {
    max-width: 50%;
    margin-left: 10rem;
  }
}

// Alineamos a la izquierda siempre los botones link en la home y la página de nosotros

.section-panel .btn-link {
  text-align: left;
}
