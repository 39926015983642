// 
// progress.scss
// Extended from Bootstrap
//

// 
// Bootstrap Overrides =====================================
//

// Rounds the progress bar, even for "multiple bar" progress bars
.progress-bar:first-child {
  border-top-left-radius: $progress-border-radius;
  border-bottom-left-radius: $progress-border-radius;
}
.progress-bar:last-child {
  border-top-right-radius: $progress-border-radius;
  border-bottom-right-radius: $progress-border-radius;
}


//
// Template ===================================
//

.progress-sm {
  height: $progress-height-sm;
}