.block {
  // border: 1px solid gold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .avatar {
    transition: transform 0.25s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 3.5rem;
      height: auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-top: 0.5rem;
  }

  h6 {
    font-size: $font-size-sm;
    text-transform: none;
    font-weight: normal;
    letter-spacing: normal;
  }

  &:hover,
  &:focus {

    .avatar {
      transform: translate3d(0, -3px, 0);
    }
  }
}

// Colors

.block {

  &-primary,
  &-blue {
    
    .avatar {
      background-color: $primary;
    }
  }

  &-green {
    
    .avatar {
      background-color: $green;
    }
  }

  &-orange {
    
    .avatar {
      background-color: $orange;
    }
  }

  &-yellow {
    
    .avatar {
      background-color: $yellow;
    }
  }

  &-gray {
    
    .avatar {
      background-color: $gray-600;
    }
  }

  &-teal {
    
    .avatar {
      background-color: $teal;
    }
  }
  
  &-cyan {
    
    .avatar {
      background-color: $cyan;
    }
  }
  
  &-pink {
    
    .avatar {
      background-color: $pink;
    }
  }
}
