// 
// buttons.scss
// Extended from Bootstrap
//


//
// Template ===================================
//

// Button white

.btn-white {
  border-color: $gray-300;

  @include hover-focus {
    background-color: $gray-100;
    border-color: $gray-400;
  }
}

.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active,
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  color: $primary;
}

.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}


// Button white 20

.btn-white-20 {
  background-color: rgba($white, .2);
  border-color: transparent;
  color: $white;

  @include hover-focus {
    background-color: rgba($white, .12);
    border-color: transparent;
    color: $white;
  }
}


// Button outline secondary

.btn-outline-secondary {
  &:not(:hover):not([aria-expanded="true"]):not([aria-pressed="true"]) {
    border-color: $gray-400;
  }
}


// Button rounded
//
// Creates circle button variations

.btn-rounded {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 2rem;
}

.btn-rounded-circle {
  width: calc(1em * #{$btn-line-height} + #{$input-btn-padding-y * 2 } + #{$btn-border-width} * 2);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}

.btn-rounded-circle.btn-lg {
  width: calc(1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2 } + #{$btn-border-width} * 2);
}

.btn-rounded-circle.btn-sm {
  width: calc(1em * #{$btn-line-height-sm} + #{$input-btn-padding-y-sm * 2 } + #{$btn-border-width} * 2);
}


// Button group
//
// Prevent buttons from jittering on hover

.btn-group .btn + .btn {
  margin-left: 0;
}

// Buttons colors
//
// --> Nuevos colores de fondo en función de los colores del proyecto

.btn-indigo {
  background-color: $indigo;
  color: $white;
}

.btn-purple {
  background-color: $purple;
  color: $white;
}

.btn-pink {
  background-color: $pink;
  color: $white;
}

.btn-red {
  background-color: $red;
  color: $white;
}

.btn-orange {
  background-color: $orange;
  color: $white;
}

.btn-yellow {
  background-color: $yellow;
  color: $white;
}

.btn-green {
  background-color: $green;
  color: $white;
}

.btn-teal {
  background-color: $teal;
  color: $white;
}

.btn-cyan {
  background-color: $cyan;
  color: $white;
}


// Solución al error texto oscuro en botón primario desactivado

.btn-primary:is(:disabled),
.btn-primary.disabled {
  color: rgba($white, 0.8);
}