@keyframes anim-fade-in {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}
@keyframes anim-fade-out {
    from {
        opacity: 1;
    } to {
        opacity: 0;
    }
}

@keyframes anim-fade-in-up {
    from {
        transform: translateY(30px);
        opacity: 0;
    } to {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes anim-fade-in-down {
    from {
        transform: translateY(-30px);
        opacity: 0;
    } to {
        transform: translateY(0px);
        opacity: 1;
    }
}
@keyframes anim-fade-out-up {
    from {
        transform: translateY(0px);
        opacity: 1;
    } to {
        transform: translateY(-30px);
        opacity: 0;
    }
}
@keyframes anim-fade-out-down {
    from {
        transform: translateY(0px);
        opacity: 1;
    } to {
        transform: translateY(30px);
        opacity: 0;
    }
}
