//
// icon.scss
// Template component
//

// Icon
//
// General styles

.icon {
  display: inline-block;

  // Feather icon

  > .fe {
    display: block;
    min-width: 1em * $line-height-base;
    min-height: 1em * $line-height-base;
    text-align: center;
    font-size: $font-size-lg;
  }

  // Active state

  &.active {
    position: relative;

    // Feather icon

    > .fe {
      mask-image: url(#{$path-to-img}/masks/icon-status.svg);
      mask-size: 100% 100%;
    }

    // Indicator

    &::after {
      content: "";
      position: absolute;
      top: 10%; right: 20%;
      width: 20%; height: 20%;
      border-radius: 50%;
      background-color: $primary;
    }
  }
}

// Feather icons
//
// Fixes icon / font vertical alignment issue

.fe {
  line-height: inherit;
}


// Icon points (New)
//
// For card panel

.icons-points {
  position: absolute;
  // width: 100%;
  right: 1rem;
  top: -0.125rem;

  img {
    width: 2rem;
    height: auto;
    padding: 0;
    margin-left: -0.5rem;
  }
}