.contenedor {
  opacity: 0.4;
  display: table;
  position: relative;
  color: #000000;
  border-style: solid;
  border-width: 2px;
  border-radius: 2%;
  border-color: black;
  justify-content: center;
  align-items: center;
  margin-top: 50%;
}

.textContainer {
  max-height: 100%;
}

.text {
  overflow-y: scroll;
  height: 100%;
  padding: 7%;
  margin-left: 2%;
}
