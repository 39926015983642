//
// sizing.scss
//

.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}

.w-05 {
  width: 0.5rem;
}

.w-075 {
  width: 0.75rem;
}

.w-1 {
  width: 1rem;
}

.w-2 {
  width: 2rem;
}

.w-3 {
  width: 3rem;
}

.w-4 {
  width: 4rem;
}

.w-5 {
  width: 5rem;
}

.w-6 {
  width: 6rem;
}

.w-7 {
  width: 7rem;
}

.h-1 {
  height: 1rem;
}

.h-2 {
  height: 2rem;
}

.h-3 {
  height: 3rem;
}

.h-4 {
  height: 4rem;
}

.h-5 {
  height: 5rem;
}

.h-6 {
  height: 6rem;
}

.h-7 {
  height: 7rem;
}